<template>
    <section class="c-dashboard">
        <app-intro>
            <h1>Accounts</h1>
            <p>Aangemaakte accounts</p>
        </app-intro>

        <transition-group class="c-grid-list" name="fade" tag="section">
            <div key="table" class="table table--bordered" v-if="accounts.length > 0">
                <div class="table__thead">
                    <div class="table__tr" v-for="account in accounts" :key="'thead-' + account.id">
                        <div class="table__th">Email</div>
                        <div class="table__th">Opgeslagen configuraties</div>
                        <div class="table__th">Datum</div>
                    </div>
                </div>
                <div class="table__tbody">
                    <div class="table__tr" v-for="account in accounts" :key="'tbody-' + account.id">
                        <div class="table__td">{{ account.email }}</div>
                        <div class="table__td">{{ account.configurations.total }}</div>
                        <div class="table__td">{{ account.created.friendly }}</div>
                    </div>
                </div>
            </div>
        </transition-group>

        <loading v-bind:nodes="accounts">Bezig met laden van de accounts...</loading>
    </section>
</template>

<script>
    import { mapState } from 'vuex';
    import AppIntro from '../components/app/AppIntro.vue';
    import Loading from '../components/Loading.vue';

    export default {
        components: { AppIntro, Loading },
        computed: {
            ...mapState({
                accounts: (state) => state.account.all,
            }),
        },
        created () {
            this.$store.dispatch('account/getAll');
        },
    };
</script>

<style lang="scss" scoped>
    @import "../assets/scss/essentials";

    .table {
        @include media-breakpoint-only(sm) {
            grid-template-columns: rem(300) 1fr;
        }

        &__tr {
            @include media-breakpoint-up(md) {
                grid-template-columns: repeat(3, 1fr);
            }

            @include media-breakpoint-up(lg) {
                grid-template-columns: repeat(2, 1fr) rem(200);
            }
        }
    }
</style>
